import React from 'react';
import './style.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
//TODO: need some refactor in styles because there is duplication
const Experts = ({ experts }) => {
  return (
    <div className="experts__cont__publications__container">
      {experts.map((expert, index) => (
        <div key={index} className="experts__cont__publications__card">
          <a
            className="experts__cont__publications__card__header"
            href={expert.linkedin}
            target="_blank"
          >
            <img
              className="experts__cont__publications__card__header_image"
              alt="linkedin"
              src={'/img/linkedin_blue.svg'}
            />
          </a>
          <div className="experts__cont__publications__card__containerImage">
            <img
              className="experts__cont__publications__card__image"
              alt="experts"
              src={expert.image.publicURL}
            />
            <div className="experts__cont__publications__card__effect"> </div>
          </div>
          <p className="experts__cont__publications__card__name">
            {expert.name_en}
          </p>
          <div className="experts__cont__publications__card__content">
            <span className="experts__cont__publications__card__content__role">
              {expert.role},
            </span>
            {` ${expert.description_en}`}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Experts;
